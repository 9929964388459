.red {
  color: red;
}

.app {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  height: 100%; /* Set height of parent container */
}

.fieldsWithBackground {
  position: relative; /* Set parent container to relative positioning */
  width: 300px; /* Example width for demonstration */
  height: 360px; /* Example height for demonstration */
  margin-top: 20px;
  /* background-color: lightgray;  */
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 300px;
  height: auto;
}

.numberField {
  position: absolute;
  border: 0px solid #000;
  padding: 3px;
  background-color: transparent;
  font-size: 20px;
  text-align: center;
  height: 22px;
}

.arrivalLeft {
  top: 31px;
  left: 100px;
  width: 50px;
}

.arrivalCenter {
  top: 31px; /* Adjust this value to match the top position of the field in the image */
  left: 162px; /* Adjust this value to match the left position of the field in the image */
  width: 60px; /* Adjust this value to match the width of the field in the image */
}

.arrivalRight {
  top: 31px;
  left: 234px; 
  width: 50px; 
}

.arrivalTotal {
  top: 61px; 
  left: 162px;
  width: 60px;
}

.sg {
  top: 168px; 
  left: 82px;
  width: 50px;
  font-size: 12px;
  transform: rotate(0deg);
  color: #000;
}

.postUpliftLeft {
  top: 210px; 
  left: 100px;
  width: 50px;

}

.postUpliftCenter {
  top: 210px; 
  left: 162px;
  width: 60px;
}

.postUpliftRight {
  top: 210px; 
  left: 234px;
  width: 50px;
}

.postUpliftTotal {
  top: 240px;
  left: 162px;
  width: 60px;
}

.upliftLiters {
  top: 180px;
  left: 135px;
  width: 60px;
}

.upliftKg {
  top: 180px;
  left: 222px;
  width: 60px;
}

.planUpliftLiters {
  top: 150px;
  left: 135px;
  width: 60px;
}

.planUpliftKg {
  top: 150px;
  left: 222px;
  width: 60px;
}

.diffKg {
  top: 170px; 
  left: 295px;
  width: 25px;
  height: 15px;
  font-size: 12px;
  transform: rotate(-90deg);
}

.sgButton {
  position: absolute;
  min-width: 9px;
  padding: 1px 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.sgButtonInc{
  top: 150px;
  left: 96px;
}

.sgButtonDec{
  top: 185px;
  left: 96px;
}

.planBlock {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  top: 97px;
  left: 120px;
  height: 40px;
  width: 130px;
  padding-left: 12px;
  border: none;
  border-radius: 5px;
}

.planBlockTitle {
  position: absolute;
  top: 94px; 
  left: 118px;
  width: 50px;
  font-size: 14px;
  color: #fff;
  transform: rotate(-90deg);
  z-index: 90;
}
.fullScreenButton {
  position: absolute;
  top: 108px;
  left: 236px;
  width: 25px;
  height: 25px;
  z-index: 90;
  cursor: pointer;
}

.fullScreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 200px;
  z-index: 1000;
  cursor: pointer;
}

.fullScreenContent {
  font-size: 300px; /* Slightly larger size for readability */
  font-weight: bold;
  color: white;
}